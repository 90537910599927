import gql from 'graphql-tag';
import {globalfunc} from '../../../../shared/GlobalFunction.js';

class MachineService {
    readQuery(){
        var query = `
            query ($paging:ServerPaging, $status:String) {
                GetMachine (Paging:$paging, status:$status) {
                    machine {
                        created_at
                        last_update
                        machine_id
                        machine_code
                        machine_name
                        machine_type_id
                        machine_type_code
                        machine_type_name
                        storage_id
                        additional_time_list
                        status
                        created_by
                    }
                    total
                }
            }
        `;
        return query;
    }

    async GetMachine(id) {
        const variables = {
            id : id,
        }
        var query = gql`
            query ($id:Int) {                
                GetMachine (machineID:$id) {
                    machine {
                        created_at
                        last_update
                        machine_id
                        machine_code
                        machine_name
                        machine_type_id
                        machine_type_code
                        machine_type_name
                        storage_id
                        additional_time_list
                        status
                        created_by
                        machine_item_list {
                            created_at
                            last_update
                            machine_item_list_id
                            item_id
                            product_code
                            item_name
                            base_uom
                            processing_time
                        }
                    }
                    total
                }
            }
        `;
        var result = await globalfunc.defaultApolloQueryDefault(query, variables);
        return result.data.GetMachine.machine[0];
    }
    
    async GetMachineTypeQuery() {
        var query = gql`
            query {
                GetMachineType {
                    machine_type {
                        machine_type_id
                        machine_type_code
                        machine_type_name
                    }
                    total
                }
            }
        `;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var storageData = [];
        for (let i = 0; i < result.data.GetMachineType.total; i++) {
            var label = result.data.GetMachineType.machine_type[i].machine_type_code + ' - ' + result.data.GetMachineType.machine_type[i].machine_type_name + ''
            var str = {
                value:result.data.GetMachineType.machine_type[i].machine_type_id,
                label:label
            }
            storageData.push(str);
        }
        return storageData
    }

    async GetStorageQuery() {
        var query = gql`
            query {
                GetStorage {
                    storage_id
                    storage_name
                }
            }
        `;
        var result = await globalfunc.defaultApolloQueryDefault(query);
        var storageData = [];
        for (let i = 0; i < result.data.GetStorage.length; i++) {
            var label = result.data.GetStorage[i].storage_id + ' - ' + result.data.GetStorage[i].storage_name + ''
            var str = {
                value:result.data.GetStorage[i].storage_id,
                label:label
            }
            storageData.push(str);
        }
        return storageData
    }

    GetMachineItemDataQuery() {        
        var query = `
            query ($paging:ServerPaging, $id:Int!) {
                GetMachineTypeItemList (Paging:$paging, machineTypeID:$id) {
                    items {
                        item_id
                        product_code
                        item_name
                        base_uom
                        type_detail_id
                    }
                    total
                }
            }
        `;
        return query;
    }
    
    selectedMachineItemConverter(data) {
        var newData = data.split(";");
        let array = [];
        for (let i = 0; i < newData.length; i++) {
            var splitData = newData[i].split("|")
            var str = {
                machine_item_list_id: null,
                item_id: splitData[0],
                product_code: splitData[1],
                item_name: splitData[2],
                base_uom: splitData[3],
                processing_time: 0
            }            
            array.push(str);
        }

        return array;
    }

    gridDataConverter(data, MachineId){
        let array = [];
        for (let i = 0; i < data.length; i++) {
            var str = {
                machine_item_list_id: data[i].machine_item_list_id,
                machine_id: MachineId,
                item_id: data[i].item_id,
                product_code: data[i].product_code,
                item_name: data[i].item_name,
                base_uom: data[i].base_uom,
                processing_time: data[i].processing_time,
            }
            array.push(str);
        }
        return array;
    }

    additionalTimeDataConverter(data){
        const obj = {};
        data.forEach(item => {
            obj[item.additional_time_reason] = item.additional_time_value;
        });
        return obj;
    }

    additionalTimeListParseData(data){   
        var additionalTime = globalfunc.destroySingleKendoProperties(data);
        var gridData = [];
        if(additionalTime != null){
            var gridData = Object.keys(additionalTime).map(key => ({
                additional_time_reason: key,
                additional_time_value: additionalTime[key]
            }));
        }     
        return gridData;
    }
    
    async addQuery(variables){
        var query = gql`
            mutation ($data:NewMasterMachine!) {
                CreateMachine (New:$data)
            }
        `;
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }
    
    async editQuery(variables){
        var query = gql`
        mutation ($id:Int!, $data:NewMasterMachine!) {
            UpdateMachine (MachineID:$id, New:$data)
        }`;
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }
    
    async deleteQuery(variables){
        var query = gql`
        mutation ($id:Int!) {
            DeleteMachine (MachineID:$id)
        }`;
        return await globalfunc.defaultApolloMutationDefault(query, variables)
    }
}

export default new MachineService();