<template>
    <div>
        <CCard class="w-100">
            <CCardHeader>
                <h2>Mesin</h2>
            </CCardHeader>
            <CCardBody>
                <span v-html="statusButton"></span>

                <div class="col-xs-6 text-light pb-2">
                    <button id="add" class="btn btn-success"                   
                        @click="addClick()">
                        <i class="fa fa-plus"></i> Add
                    </button>
                </div>

                <machine-grid ref="grid" :key="gridReload" :editClick="editClick" :deleteClick="deleteClick" :statusClick="statusClick" />
                <machine-status-form ref="MachineStatusModal" :reload="reload"/>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import { response } from '../../../infrastructure/constant/response';
import { sweet_alert } from '../../../infrastructure/constant/variable';

import machineGrid from '../Machine/Grid/MachineGrid.vue';
import machineStatusForm from '../Machine/Component/MachineStatusForm.vue';
import machineService from './Script/MachineServices.js';

export default {
    name: 'Machine',
    components: {
        'machine-grid': machineGrid,
        'machine-status-form': machineStatusForm,
    },
    async mounted () {
        var permission = this.$globalfunc.permissionModule('Machine');
        document.getElementById("add").disabled = permission.cperms ? false : true;
        
        this.statusFilter();
    },
    data(){
        return{
            gridReload: 0,
            statusButton: '',
        }
    },
    methods: {
        addClick(){
            this.$router.push({ name: 'Machine Form', params: {  formtype:'Add', id:' ', view:'new' } });
        },
        editClick(id, view) {
            const routeData = this.$router.resolve({ name: 'Machine Form', params: {  formtype:'Edit', id:id, view:view } });
            window.open(routeData.href, '_blank');
        },
        deleteClick(id) {
            this.$swal(sweet_alert.delete).then((result) => {
                if (result.isConfirmed == true) {
                    this.$loading(true);

                    const variables = {
                        id: parseInt(id),
                    }
                    
                    machineService.deleteQuery(variables).then(res => {
                        this.$loading(false);
                        this.$swal("Info", response.successDelete, "success");
                        this.reload();
                    }).catch(error => {
                        this.$loading(false);
                        var errorMessage = this.$globalfunc.errorExtractor(error)
                        this.$swal("Error", errorMessage, "error");
                    })
                }
            });
        },
        statusClick(data){
            this.$refs.MachineStatusModal.statusClick(data.machine_id, data.machine_code, data.status);
        },
        async statusFilter() {
            this.statusButton = await this.$globalfunc.dynamicStatusButton();
            
            var vue = this;
            this.$nextTick(function () {
                $("#btnAll").on('click', function() {
                    vue.reload();
                });
                $("#btnNew").on('click', function() {
                    vue.$refs.grid.changeStatus('New');
                });
                $("#btnReady").on('click', function() {
                    vue.$refs.grid.changeStatus('Ready');
                });
                $("#btnProcessing").on('click', function() {
                    vue.$refs.grid.changeStatus('Processing');
                });
                $("#btnOnHold").on('click', function() {
                    vue.$refs.grid.changeStatus('On Hold');
                });
            })
        },
        reload(){
            this.gridReload++;
        },
    }
}
</script>

<style scoped>
</style>